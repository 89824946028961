import React from "react";
import "./About.css";
// import profileimg2 from "../../assets/profile2.png";
import profileimg2 from "../../assets/boy.png";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  AOS.init({
    duration: 1200,
  });

  return (
    <div id="about-section">
      <div className="about-wrapper">
        <div className="about-container">
          <div className="about-header" data-aos="fade-down">
            <h1>ABOUT ME</h1>
          </div>
          <div className="about-content">
            <div className="about-p" data-aos="fade-up">
              <hr className="about-hr"></hr>
              <p>
                 I am an experienced QA Automation Engineer with expertise in
                testing methodologies, automation tools like Cypress,
                Playwright, JavaScript, and TypeScript, and a strong background
                in database technologies such as MongoDB and SQL. My skills also
                extend to programming languages like .NET, PHP, and C#.
                Currently, I serve as a QA Lead at 91Life and Matrics
                Blockchain, where I lead a team to implement rigorous testing
                practices. With a background as a freelance full-stack
                developer, I have a comprehensive understanding of both frontend
                and backend development.
              </p>
            </div>
            <div className="about-img" data-aos="zoom-out-down">
              <img src={profileimg2} alt="/" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
